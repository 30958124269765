<template>
  <div id="public-user-profile">
    <b-navbar fixed="top" :sticky="true" class="navbar-pubic-profile">
      <div class="container">
        <b-col cols="12" sm="12" lg="8" offset-lg="2" class="d-flex justify-content-between align-items-center">
          <b-navbar-brand href="https://avify.net/">
            <Logo></Logo>
          </b-navbar-brand>
          <b-link type="submit" class="btn btn-secondary" href="https://avify.net/" target="_blank">Visita la web de Avify</b-link>
        </b-col>
      </div>
    </b-navbar>
    <b-container fluid="sm" class="content public-user-profile-container">
      <b-row align-h="center">
        <b-col cols="12" sm="12" lg="8" v-if="!isLoadingData">
          <profile-card
            :editable="false"
            :user="user"
            :extendedUser="user.getTechnician">
          </profile-card>
          <about-me-card
            :editable="false"
            :user="user"
            :extendedUser="user.getTechnician">
          </about-me-card>
          <professional-profile-card
            :editable="false"
            :user="user"
            :extendedUser="user.getTechnician"
          >
          </professional-profile-card>
          <languages-card
            :editable="false"
            :userLanguages="user.getTechnician.getLanguages">
          </languages-card>
          <certification-card
            :editable="false"
            :userCertifications="user.getTechnician.getCertifications">
          </certification-card>
          <jobs-card
            :editable="false"
            :userJobs="user.getTechnician.getJobs">
          </jobs-card>
          <hardware-card
            :editable="false"
            :userHardware="user.getTechnician.getHardware">
          </hardware-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AboutMeCard from '../components/AboutMe/AboutMeCard'
import ProfessionalProfileCard from '../components/ProfessionalProfile/ProfessionalProfileCard'
import LanguagesCard from '../components/Languages/LanguagesCard'
import CertificationCard from '../components/Certification/CertificationCard'
import JobsCard from '../components/Jobs/JobsCard'
import HardwareCard from '../components/Hardware/HardwareCard'
import Logo from '@/components/Header/Logo/Logo.vue'
import ProfileCard from '../components/Profile/ProfileCard'

export default {
  name: 'PublicUserProfile',
  components: {
    ProfileCard,
    CertificationCard,
    JobsCard,
    HardwareCard,
    LanguagesCard,
    ProfessionalProfileCard,
    AboutMeCard,
    Logo
  },
  data () {
    return {
      isLoadingData: true
    }
  },
  created: async function () {
    this.$store.dispatch('lockSendFormSaving')
    this.user = await this.$store.dispatch('getPublicUserInfo', this.$route.params.slug)
    this.isLoadingData = !this.isLoadingData
    this.$store.dispatch('unlockSendFormSaving')
  }
}
</script>

<style scoped lang="less">
@import "publicUserProfile.less";
</style>
